<template>
    <div class="p-3">
        <el-card shadow="never">
            <div class="row no-gutters">
                <div class="col">
                    <search-box v-model="key" style="width: 280px;" @search="loadSuppliers(true)" />
                </div>
            </div>
            <el-table :data="suppliers.data" class="mt-2">
                <el-table-column prop="registeredUser.name" label="企业名称/姓名" sortable>
                    <template #default="scope">
                        <router-link :to="'/registered-user/detail?id='+scope.row.registeredUser.id" target="_blank">
                            {{scope.row.registeredUser.name}}
                        </router-link>
                    </template>
                </el-table-column>
                <el-table-column prop="registeredUser.idCard" label="统一社会信用代码/身份证号" sortable />
                <el-table-column prop="contactName" label="联系人" sortable />
                <el-table-column prop="contactPhone" label="联系电话" sortable />
                <el-table-column label="操作" fixed="right" width="70">
                    <template #default="scope">
                        <el-button type="text" @click="showSettleDialog(scope.row)">结算</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination layout="total,->,prev,pager,next" :total="suppliers.totalRecords"
                :current-page="suppliersPagination.currentPage" :page-size="suppliersPagination.pageSize" class="mt-3"
                @current-change="suppliersPaginationCurrentChange" />
        </el-card>

        <el-dialog :visible.sync="settleDialogVisible" title="结算" :close-on-click-modal="false" width="960px" top="0">
            <div style="margin: 0 -16px;">
                <el-form>
                    <div class="form-group-title">订单项</div>
                    <div class="form-group">
                        <div class="border rounded">
                            <div class="border-bottom p-2">
                                <el-date-picker v-model="beginTime" placeholder="开始日期" style="width: 160px;"
                                    @change="loadOrderItems" />
                                <el-date-picker v-model="endTime" placeholder="开始日期" class="ml-2" style="width: 160px;"
                                    @change="loadOrderItems" />
                            </div>
                            <el-table :data="orderItems" @selection-change="orderItemSelectionChange">
                                <el-table-column type="selection" align="center" width="50" />
                                <el-table-column width="90">
                                    <template #default="scope">
                                        <el-image :src="scope.row.productSnapshot.pictureUrl"
                                            :preview-src-list="[scope.row.productSnapshot.pictureUrl]" fit="fill"
                                            style="width: 64px; height: 64px;" />
                                    </template>
                                </el-table-column>
                                <el-table-column label="商品">
                                    <template #default="scope">
                                        <div>{{scope.row.productSnapshot.name}}</div>
                                        <div class="text-info">
                                            {{scope.row.number}}（{{scope.row.productSnapshot.unit}}）共
                                            {{scope.row.totalPrice}} 元
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="买家" align="right" width="120">
                                    <template #default="scope">
                                        <div>{{scope.row.buyerName}}</div>
                                        <div class="text-info">{{$moment.sd(scope.row.paymentTime)}}</div>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div class="p-2">共 {{orderItems.length}} 条</div>
                        </div>
                    </div>

                    <div class="form-group-title">结算</div>
                    <div class="form-group">
                        <el-row :gutter="8">
                            <el-col :span="8">
                                <el-form-item label="合计金额（元）">
                                    <el-input :value="totalAmount" disabled />
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="结算比例">
                                    <el-input :value="(settleRate*100).toFixed(2)+' %'" disabled />
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="结算金额（元）">
                                    <el-input-number v-model="settleAmount" controls-position="right" :min="0"
                                        :precision="2" />
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>

                    <div class="form-group-title">备注</div>
                    <div class="form-group">
                        <el-form-item label="备注">
                            <el-input v-model="settleRemark" placeholder="备注" />
                        </el-form-item>
                    </div>
                </el-form>
            </div>
            <template #footer>
                <el-button type="primary" :disabled="selectedOrderItems.length==0" @click="addSettlement">确定</el-button>
                <el-button @click="settleDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                key: null,
                suppliers: {},
                suppliersPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
                supplier: { registeredUser: {} },
                beginTime: new Date(2022, 0, 1),
                endTime: new Date(),
                orderItems: [],
                selectedOrderItems: [],
                settleAmount: 0,
                settleRemark: null,
                settleDialogVisible: false,
            };
        },
        computed: {
            totalAmount() {
                let result = 0;
                for (const orderItem of this.selectedOrderItems) {
                    result += orderItem.totalPrice;
                }
                return result;
            },
            settleRate() {
                if (this.totalAmount == 0) {
                    return 0;
                }
                return this.settleAmount / this.totalAmount;
            },
        },
        methods: {
            async loadSuppliers(resetPage = false) {
                if (resetPage) {
                    this.suppliersPagination.currentPage = 1;
                }

                let response = await this.$axios.get('/api/Supplier/GetSettleSuppliers', {
                    params: {
                        companyId: this.$store.state.companyId,
                        key: this.key,
                        pageIndex: this.suppliersPagination.currentPage - 1,
                        pageSize: this.suppliersPagination.pageSize,
                    }
                });
                this.suppliers = response.data;
            },
            suppliersPaginationCurrentChange(page) {
                this.suppliersPagination.currentPage = page;
                this.loadSuppliers();
            },
            showSettleDialog(supplier) {
                this.supplier = JSON.parse(JSON.stringify(supplier));
                this.settleAmount = 0;
                this.settleRemark = null;
                this.settleDialogVisible = true;

                this.loadOrderItems(true);
            },
            async loadOrderItems() {
                let response = await this.$axios.get('/api/Settlement/GetNoSettledOrderItems', {
                    params: {
                        supplierId: this.supplier.id,
                        beginTime: this.$moment(this.beginTime).format('YYYY/M/D'),
                        endTime: this.$moment(this.endTime).format('YYYY/M/D'),
                        key: this.key,
                    }
                });
                this.orderItems = response.data.data;
            },
            orderItemSelectionChange(value) {
                this.selectedOrderItems = value;
                this.settleAmount = this.totalAmount * 0.8;
            },
            async addSettlement() {
                if (!confirm('确定要结算吗？')) {
                    return;
                }

                await this.$axios.post('/api/Settlement/AddSettlement', {
                    supplierId: this.supplier.id,
                    orderItemIds: this.selectedOrderItems.map(p => p.id),
                    amount: this.settleAmount,
                    remark: this.settleRemark,
                });
                this.settleDialogVisible = false;
                this.loadSuppliers();
            },
        },
        created() {
            this.loadSuppliers();
        },
    };
</script>

<style>
    .form-group-title {
        background-color: #f2f6fc;
        padding: 1em 3em;
        border-left: 4px solid #409eff;
        font-weight: bold;
    }

    .form-group {
        padding: 1em 3em;
    }
</style>